<template>
  <q-list>
    <q-item
      clickable
      :to="{ name: item.routeName }"
      exact
      v-for="item in itens"
      :key="item.title"
    >
      <q-item-section
        v-if="item.icon"
        avatar
      >
        <q-icon :name="item.icon" />
      </q-item-section>

      <q-item-section>
        <q-item-label>{{ item.title }}</q-item-label>
        <q-item-label v-if="item.caption" caption>
          {{ item.caption }}
        </q-item-label>
      </q-item-section>
    </q-item>
  </q-list>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'MainMenu',
  props: {
    itens: {
      type: Array,
      required: true
    }
  }
})
</script>
